<script setup lang="ts">
  import { RouterLink } from 'vue-router'

  import type { Theme } from '../types'
  import { useProfileStore } from '../stores'

  const profileStore = useProfileStore()
  const profiles = profileStore.getAllProfiles()

  const navStyleByTheme = (navTheme: Theme) => ({
    color: navTheme.color
  })
</script>

<template>
  <header class="navbar bg-lh-banner fixed w-full">
    <div class="navbar-content flex flex-row justify-between">
      <div class="navbar-title py-2 px-8 h-24">
        <router-link to="/" class="flex align-center gap-4">
          <img class="h-20" :src="`/LH_Icon.png`" alt="LoreHeart">
          <div class="text-4xl text-lh-lore mt-2">
            <span class="text-lh-lore">Lore</span>
            <span class="text-lh-heart">Heart</span>
          </div>
        </router-link>
      </div>
      <div class="navbar-links flex pt-2 px-8 h-24 gap-16">
        <div
          v-for="profile in profiles"
          :key="`nav-${profile.link}`"
          class="p-2 clickable"
          :style="navStyleByTheme(profile.theme)">
          <router-link :to="`/${profile.link}`" replace>
            {{ profile.nav }}
          </router-link>
          
        </div>
      </div>
    </div>
  </header>
</template>

<style>
  .navbar {
    font-size: 2.4rem;
    /* font-family: 'Caveat', cursive; */
    margin: 0;
    @media screen and (max-width: 64rem) {
      font-size: 2rem;
      .navbar-content {
        display: flex;
        justify-content: center;
        align-items: center;
        flex-direction: column;
        .navbar-links {
          padding: 0;
        }
      }
    }
    .navbar-title {
      padding: 0.5rem;
      font-size: 2rem;
      height: inherit;
      img {
        margin: 0.5rem 0 0 2rem;
        height: 2.5rem;
      }
    }
    .navbar-links {
      font-size: 1.8rem;
      padding: 1rem 2rem;
      height: inherit;
      .clickable {
        padding: 0.15rem 0.5rem;
      }
    }
  }
</style>