import type { ThemeIndex } from '../types'

export const LOREHEART_THEMES: ThemeIndex = {
  'lore': {
    color: '#1affff',
    backgroundColor: 'rgba(0, 36, 36, 0.90)',
    titleBackgroundColor: 'rgba(0, 36, 36, 0.90)',
    glow: true
  },
  'heart': {
    color: '#b073d9',
    backgroundColor: 'rgba(42, 16, 60, 0.90)',
    titleBackgroundColor: 'rgba(42, 16, 60, 0.90)',
    glow: true
  },
  'default': {
    color: '#ff75ff',
    backgroundColor: 'rgba(36, 0, 36, 0.90)',
    titleBackgroundColor: 'rgba(36, 0, 36, 0.90)',
    glow: true
  }
}

export const spotlightBackground = (
  backgroundImage: string) => {
  return {
    'background': 
      `radial-gradient(ellipse, rgba(8, 2, 16, 0.6), rgb(8, 2, 16)),
      url("/${backgroundImage}") no-repeat center fixed`,
    'background-size': 'cover',
  }
}