export const LOREHEART_PROFILES = [
  {
    name: 'Kaia Loriet',
    title: 'Digital Experience Engineer',
    nav: 'Lore',
    link: 'loriet',
    codepen: 'lawlheart',
    image: 'https://avatars.githubusercontent.com/u/71208283',
    about: [
      'Passionate problem-solver with deep technical expertise, turning complex ideas into clear, actionable solutions with a strong focus on usability, accessibility, and user experience.',
      'Over 7 years in agile software engineering, crafting precise and secure digital products for healthcare and finance industries.',
      '5 years as a FreeCodeCamp PDX Organizer, fostering collaboration and mentorship in a diverse community.',
    ],
    theme: {
      color: '#1affff',
      backgroundColor: 'rgba(0, 36, 36, 0.90)',
      titleBackgroundColor: 'rgba(0, 36, 36, 0.90)',
      glow: true
    },
    links: [
      {
        name: 'Lawlheart',
        site: 'Codepen',
        url: 'https://codepen.io/lawlheart',
      },
      {
        name: 'Lawlheart',
        site: 'GitHub',
        url: 'https://github.com/Lawlheart',
      },
      {
        name: 'Lawlheart',
        site: 'LinkedIn',
        url: 'https://linkedin.com/in/lawlheart/',
      },
      {
        name: 'Lawlheart',
        site: 'Contra',
        url: 'https://lawlheart.contra.com',
      },
      {
        name: '@kaialoriet (2021-present)',
        site: 'FreeCodeCamp',
        url: 'https://www.freecodecamp.org/kaialoriet',
      },
      {
        name: '@lawlheart (pre 2021)',
        site: 'FreeCodeCamp',
        url: 'https://www.freecodecamp.org/lawlheart',
      }
    ],
    certifications: [
      {
        name: "Front End Development",
        prefix: "FE",
        issuer: "FreeCodeCamp",
        cred: "https://www.freecodecamp.org/certification/kaialoriet/responsive-web-design",
        projects: [
          {
            "certCode": "RD-01",
            "name": "Tribute Page",
            "skills": "Responsive Design, JavaScript, HTML, CSS",
            "description": "Tribute page for Gorrilaz depicting band members",
            "start": "09/02/2021",
            "updated": "09/30/2021",
            "source": "https://codepen.io/lawlheart/pen/MWoemRO",
            "demo": "https://codepen.io/lawlheart/full/MWoemRO"
          },
          {
            "certCode": "RD-02",
            "name": "Survey Form",
            "skills": "Responsive Design, JavaScript, HTML, CSS",
            "description": "Survey form for adopting cartoon monsters",
            "start": "09/04/2021",
            "updated": "09/07/2021",
            "source": "https://codepen.io/lawlheart/pen/wveyYeN",
            "demo": "https://codepen.io/lawlheart/full/wveyYeN"
          },
          {
            "certCode": "RD-03",
            "name": "Product Landing Page",
            "skills": "Responsive Design, JavaScript, HTML, CSS",
            "description": "Product landing form for Slurm",
            "start": "09/08/2021",
            "updated": "09/23/2021",
            "source": "https://codepen.io/lawlheart/pen/KKqavGB",
            "demo": "https://codepen.io/lawlheart/full/KKqavGB"
          },
          {
            "certCode": "RD-04",
            "name": "Technical Documentation Page",
            "skills": "Responsive Design, JavaScript, HTML, CSS",
            "description": "Frontend tricks and treats - a halloween themed code demo site",
            "start": "09/14/2021",
            "updated": "09/20/2021",
            "source": "https://codepen.io/lawlheart/pen/eYRGQNL",
            "demo": "https://codepen.io/lawlheart/full/eYRGQNL"
          },
          {
            "certCode": "RD-05",
            "name": "Portfolio",
            "skills": "Responsive Design, JavaScript, HTML, CSS",
            "description": "Single page portfolio site showcasing latest frontend projects",
            "start": "09/24/2021",
            "updated": "09/28/2021",
            "source": "https://codepen.io/lawlheart/pen/BaZqwxz",
            "demo": "https://codepen.io/lawlheart/full/BaZqwxz"
          }
        ],
      },
      {
        name: "Data Visualization (Legacy Certification)",
        prefix: "LDV",
        issuer: "FreeCodeCamp",
        cred: "https://www.freecodecamp.org/certification/lawlheart/legacy-data-visualization",
        projects: [
          {
            "certCode": "LDV-01",
            "name": "Markdown Previewer",
            "skills": "HTML, CSS, JavaScript, React",
            "description": "Markdown preview page built using React",
            "start": "01/02/2016",
            "updated": "01/02/2016",
            "source": "https://github.com/Lawlheart/FCC-Ziplines/tree/master/Zipline-11",
            "demo": "https://codepen.io/lawlheart/pen/vLgmdm",
          },
          {
            "certCode": "LDV-02",
            "name": "Build a Camper Leaderboard",
            "skills": "HTML, CSS, JavaScript, React",
            "description": "Camper leaderboard built in React",
            "start": "01/04/2016",
            "updated": "01/04/2016",
            "source": "https://github.com/Lawlheart/FCC-Ziplines/tree/master/Zipline-12",
            "demo": "https://codepen.io/lawlheart/pen/rxjZEq",
          },
          {
            "certCode": "LDV-03",
            "name": "Build a Recipe Box",
            "skills": "HTML, CSS, JavaScript, React",
            "description": "Recipe box app built using React",
            "start": "01/19/2016",
            "updated": "01/31/2016",
            "source": "https://github.com/Lawlheart/FCC-Ziplines/tree/master/Zipline-13",
            "demo": "https://codepen.io/lawlheart/pen/gPgqGR",
          },
          {
            "certCode": "LDV-04",
            "name": "Game of Life",
            "skills": "HTML, CSS, JavaScript, React",
            "description": "Conway's game of life with grid size, generation speed, pause/play, and randomize functions. Built in React on CodePen.",
            "start": "01/31/2016",
            "updated": "09/19/2016",
            "source": "https://github.com/Lawlheart/FCC-Ziplines/tree/master/Zipline-14",
            "demo": "https://codepen.io/lawlheart/pen/oboRwo",
          },
          {
            "certCode": "LDV-05",
            "name": "Roguelike Game",
            "skills": "HTML, CSS, JavaScript, React, Vue",
            "description": "Roguelike dungeon game. Players can explore the dungeon, find gear, fight monsters, gain experience, and level up. Defeat the boss to win. Originally built in React, Vue version on CodePen.",
            "start": "01/31/2016",
            "updated": "02/02/2016",
            "source": "https://github.com/Lawlheart/FCC-Ziplines/tree/master/Zipline-15",
            "demo": "https://codepen.io/lawlheart/pen/JGBazL",
          },
          {
            "certCode": "LDV-06",
            "name": "Bar Chart",
            "skills": "HTML, CSS, JavaScript, AngularJS, d3.js",
            "description": "Bar chart depicting US GDP built with d3.js",
            "start": "02/04/2016",
            "updated": "02/04/2016",
            "source": "https://github.com/Lawlheart/FCC-Ziplines/tree/master/Zipline-16",
            "demo": "https://codepen.io/lawlheart/pen/bExgVR",
          },
          {
            "certCode": "LDV-07",
            "name": "Scatterplot Graph",
            "skills": "HTML, CSS, JavaScript, AngularJS, d3.js",
            "description": "Scatterplot graph depicting doping in Professional Bicycle Racing",
            "start": "02/04/2016",
            "updated": "02/04/2016",
            "source": "https://github.com/Lawlheart/FCC-Ziplines/tree/master/Zipline-17",
            "demo": "https://codepen.io/lawlheart/pen/eJLQEQ",
          },
          {
            "certCode": "LDV-08",
            "name": "Heat Map",
            "skills": "HTML, CSS, JavaScript, AngularJS, d3.js",
            "description": "D3.js heat map comparing exoplanet radius, mass, and distance from Earth. Utilizes data from NASA Kepler Mission",
            "start": "02/04/2016",
            "updated": "02/12/2016",
            "source": "https://github.com/Lawlheart/FCC-Ziplines/tree/master/Zipline-18",
            "demo": "https://codepen.io/lawlheart/pen/wMEbBV",
          },
          {
            "certCode": "LDV-09",
            "name": "Force Directed Map",
            "skills": "HTML, CSS, JavaScript, AngularJS, d3.js",
            "description": "Force directed chart built with d3.js depicting the relationships between campers",
            "start": "02/06/2016",
            "updated": "02/12/2016",
            "source": "https://github.com/Lawlheart/FCC-Ziplines/tree/master/Zipline-19",
            "demo": "https://codepen.io/lawlheart/pen/YwJebb",
          },
          {
            "certCode": "LDV-10",
            "name": "Map Data Across the Globe",
            "skills": "HTML, CSS, JavaScript, AngularJS, d3.js",
            "description": "D3.js map showing meteor impact sites. The relative size of the point corresponds to the mass of the meteor and the color of the point represents the meteor class.",
            "start": "02/15/2016",
            "updated": "02/15/2016",
            "source": "https://github.com/Lawlheart/FCC-Ziplines/tree/master/Zipline-20",
            "demo": "https://codepen.io/lawlheart/pen/VeRjXG",
          }
        ],
      },
      {
        name: "Back End (Legacy Certification)",
        prefix: "LBE",
        issuer: "FreeCodeCamp",
        cred: "https://www.freecodecamp.org/certification/lawlheart/legacy-back-end",
        projects: [
          {
            "certCode": "LBE-01",
            "name": "Timestamp Microservice",
            "skills": "HTML, CSS, JavaScript, AngularJS, Node.js, Express",
            "description": "API endpoint that checks for a valid date input and returns the date in both unix format and as a readable string",
            "start": "02/16/2016",
            "updated": "02/23/2016",
            "source": "https://github.com/Lawlheart/timestamp",
            "demo": "",
          },
          {
            "certCode": "LBE-02",
            "name": "Request Header Parser",
            "skills": "HTML, CSS, JavaScript, AngularJS, Node.js, Express",
            "description": "API endpoint that responds with data about the request headers",
            "start": "02/18/2016",
            "updated": "02/23/2016",
            "source": "https://github.com/Lawlheart/header-parser",
            "demo": "",
          },
          {
            "certCode": "LBE-03",
            "name": "URL Shortener",
            "skills": "HTML, CSS, JavaScript, AngularJS, Node.js, Express",
            "description": "API endpoint that allows the user to create and use URL redirects.",
            "start": "02/18/2016",
            "updated": "02/23/2016",
            "source": "https://github.com/Lawlheart/url-shortener",
            "demo": "",
          },
          {
            "certCode": "LBE-04",
            "name": "Image Search Layer",
            "skills": "HTML, CSS, JavaScript, AngularJS, Node.js, Express",
            "description": "API endpoint that allows a user to search Google for images. ",
            "start": "02/19/2016",
            "updated": "02/23/2016",
            "source": "https://github.com/Lawlheart/image-search",
            "demo": "",
          },
          {
            "certCode": "LBE-05",
            "name": "File Metadata Microservice",
            "skills": "HTML, CSS, JavaScript, AngularJS, Node.js, Express",
            "description": "API endpoint for analysing properties of uploaded files",
            "start": "02/23/2016",
            "updated": "02/23/2016",
            "source": "https://github.com/Lawlheart/file-metadata",
            "demo": "",
          },
          {
            "certCode": "LBE-06",
            "name": "Voting App",
            "skills": "HTML, CSS, JavaScript, AngularJS, Node.js, MongoDB, oAuth, Express",
            "description": "MEAN stack app Voting app with oAuth that allows users to login, create polls, and share them with others",
            "start": "09/14/2015",
            "updated": "12/11/2015",
            "source": "https://github.com/Lawlheart/vote",
            "demo": "",
          },
          {
            "certCode": "LBE-07",
            "name": "Nightlife Coordination App",
            "skills": "HTML, CSS, JavaScript, AngularJS, Node.js, MongoDB, oAuth, Express",
            "description": "MEAN stack app that allows the user to search for local bars or restraunts and shows locations and ratings using the Yelp API",
            "start": "09/25/2015",
            "updated": "12/11/2015",
            "source": "https://github.com/Lawlheart/nightlife",
            "demo": "",
          },
          {
            "certCode": "LBE-08",
            "name": "Chart the Stock Market",
            "skills": "HTML, CSS, JavaScript, AngularJS, Node.js, MongoDB, oAuth, Express",
            "description": "MEAN stack app that charts the stock market using data from the Markit on demand Market Data API",
            "start": "10/10/2015",
            "updated": "12/11/2015",
            "source": "https://github.com/Lawlheart/stocks",
            "demo": "",
          },
          {
            "certCode": "LBE-09",
            "name": "Manage a Book Trading Club",
            "skills": "HTML, CSS, JavaScript, AngularJS, Node.js, MongoDB, oAuth, Express",
            "description": "MEAN stack app that allows users to trade books",
            "start": "12/17/2015",
            "updated": "12/19/2015",
            "source": "https://github.com/Lawlheart/books",
            "demo": "",
          },
          {
            "certCode": "LBE-10",
            "name": "Build a Pinterest Clone",
            "skills": "HTML, CSS, JavaScript, AngularJS, Node.js, MongoDB, oAuth, Express, Sass",
            "description": "MEAN stack app",
            "start": " Fake quote sharing site inspired by John Oliver’s segment on misappropriated quotes",
            "updated": "04/05/2016",
            "source": "02/24/2016",
            "demo": "https://github.com/Lawlheart/real-quotes",
          }
        ],
      },
      {
        name: "Front End (Legacy Certification)",
        prefix: "LFE",
        issuer: "FreeCodeCamp",
        cred: "https://www.freecodecamp.org/certification/lawlheart/legacy-front-end",
        projects: [
          {
            "certCode": "LFE-01",
            "name": "Personal Portfolio Webpage",
            "skills": "HTML, CSS, JavaScript, Polymer",
            "description": "First Portfolio site, originally built in Polymer and Angular 1, ported to Angular 2",
            "start": "06/15/2015",
            "updated": "09/25/2017",
            "source": "https://github.com/Lawlheart/FCC-Ziplines/tree/master/Zipline-01",
            "demo": "https://codepen.io/lawlheart/pen/LpPXBb",
          },
          {
            "certCode": "LFE-02",
            "name": "Random Quote Machine",
            "skills": "Responsive Design, JavaScript, HTML, CSS",
            "description": "Site with a button to show a new random quote and another button to tweet it",
            "start": "08/31/2015",
            "updated": "01/02/2016",
            "source": "https://github.com/Lawlheart/FCC-Ziplines/tree/master/Zipline-02",
            "demo": "https://codepen.io/lawlheart/pen/NGWvWe",
          },
          {
            "certCode": "LFE-03",
            "name": "Show the Local Weather",
            "skills": "Responsive Design, JavaScript, HTML, CSS",
            "description": "Site showing local weather in C and F, queries weather by zip code",
            "start": "09/01/2015",
            "updated": "01/02/2016",
            "source": "https://github.com/Lawlheart/FCC-Ziplines/tree/master/Zipline-03",
            "demo": "https://codepen.io/lawlheart/pen/bVGmPq",
          },
          {
            "certCode": "LFE-04",
            "name": "Pomodoro Clock",
            "skills": "Responsive Design, JavaScript, HTML, CSS",
            "description": "Site with a working pomodoro style timer with adjustable interjvals",
            "start": "09/02/2015",
            "updated": "09/03/2015",
            "source": "https://github.com/Lawlheart/FCC-Ziplines/tree/master/Zipline-04",
            "demo": "https://codepen.io/lawlheart/pen/QjwLrd",
          },
          {
            "certCode": "LFE-05",
            "name": "Use the Twitch.tv JSON API",
            "skills": "Responsive Design, JavaScript, HTML, CSS",
            "description": "Site showing twitch streams, toggleable to show online or offline",
            "start": "09/03/2015",
            "updated": "09/26/2016",
            "source": "https://github.com/Lawlheart/FCC-Ziplines/tree/master/Zipline-05",
            "demo": "https://codepen.io/lawlheart/pen/yYyNEo",
          },
          {
            "certCode": "LFE-06",
            "name": "Stylize Stories on Camper News",
            "skills": "Responsive Design, JavaScript, HTML, CSS",
            "description": "Site showing cards with links to camper news articles",
            "start": "09/08/2015",
            "updated": "02/26/2016",
            "source": "https://github.com/Lawlheart/FCC-Ziplines/tree/master/Zipline-06",
            "demo": "https://codepen.io/lawlheart/pen/YyXjpo",
          },
          {
            "certCode": "LFE-07",
            "name": "Wikipedia Viewer",
            "skills": "Responsive Design, JavaScript, HTML, CSS",
            "description": "Site that queries wikipedia API and shows search links",
            "start": "09/08/2015",
            "updated": "09/09/2015",
            "source": "https://github.com/Lawlheart/FCC-Ziplines/tree/master/Zipline-07",
            "demo": "https://codepen.io/lawlheart/pen/avOXPE",
          },
          {
            "certCode": "LFE-08",
            "name": "JavaScript Calculator",
            "skills": "HTML, CSS, JavaScript, AngularJS",
            "description": "A working calculator built with JavaScript in AngularJS",
            "start": "09/09/2015",
            "updated": "09/09/2015",
            "source": "https://github.com/Lawlheart/FCC-Ziplines/tree/master/Zipline-08",
            "demo": "https://codepen.io/lawlheart/pen/qOOdPV",
          },
          {
            "certCode": "LFE-09",
            "name": "Tic Tac Toe Game",
            "skills": "HTML, CSS, JavaScript, AngularJS",
            "description": "Working Tic Tac Toe game that *should* never lose, at worst draws",
            "start": "09/11/2015",
            "updated": "02/26/2016",
            "source": "https://github.com/Lawlheart/FCC-Ziplines/tree/master/Zipline-09",
            "demo": "https://codepen.io/lawlheart/pen/pjjLee",
          },
          {
            "certCode": "LFE-10",
            "name": "Simon Game",
            "skills": "HTML, CSS, JavaScript, AngularJS",
            "description": "Working recreation of the Simon sound and color game with 2 modes.",
            "start": "09/13/2015",
            "updated": "09/13/2015",
            "source": "https://github.com/Lawlheart/FCC-Ziplines/tree/master/Zipline-10",
            "demo": "https://codepen.io/lawlheart/pen/RWWzLz",
          }
        ],
      },
    ],
    projects: [
      {
        "name": "PokeLab",
        "skills": "TypeScript, Vue, Pinia, HTML, CSS, JavaScript",
        "description": "Pokemon Multitool site in development",
        "start": "08/4/2024",
        "updated": "8/20/2024",
        "source": "https://github.com/loreheart/poke-lab",
        "demo": "https://pokelab.loreheart.com"
      },
      {
        "name": "Persona 4 Golden Guide",
        "skills": "HTML, CSS, JavaScript, Angular, Vue",
        "description": "A website guide for Persona 4 Golden, initially built using a node.js back end with express and Swig, then migrated to an Angular SPA. CodePen version is in Vue.",
        "start": "07/22/2015",
        "updated": "2/9/2024",
        "source": "https://github.com/Lawlheart/Persona-4-Golden-Guide",
        "demo": "https://codepen.io/lawlheart/pen/RwZPLVJ"
      },
      {
        "name": "Pokedex",
        "skills": "HTML, CSS, JavaScript, Angular, Vue",
        "description": "Pokedex site with a working IV calculator (not active in Vue Codepen version yet). Built using AngularJS, Sass, Bourbon, and PokeApi. CodePen version is in Vue.",
        "start": "07/23/2015",
        "updated": "2/9/2024",
        "source": "https://github.com/Lawlheart/pokedex",
        "demo": "https://codepen.io/lawlheart/pen/GREbjLJ"
      },
      {
        "name": "Civilization V Leaders",
        "skills": "HTML, CSS, JavaScript, Angular, Vue",
        "description": "A Reference Page for Civilization V with all of the available leaders in the base game and DLCs that shows each leader\"s unique ability, units, and buildings.",
        "start": "06/19/2015",
        "updated": "2/9/2024",
        "source": "https://github.com/Lawlheart/civ-leaders",
        "demo": "https://codepen.io/lawlheart/pen/QWMNKmg"
      },
      {
        "name": "Fallout Terminal Game",
        "skills": "HTML, CSS, JavaScript, AngularJS",
        "description": "Recreation of the hacking minigame in Fallout 4.",
        "start": "10/01/2015",
        "updated": "2/10/2024",
        "source": "https://github.com/Lawlheart/fallout-terminal",
        "demo": "https://codepen.io/lawlheart/pen/eJOZjN"
      },
      {
        "name": "Meteor Strike Map",
        "skills": "HTML, CSS, JavaScript, AngularJS, d3.js",
        "description": "D3.js map showing meteor impact sites. The relative size of the point corresponds to the mass of the meteor and the color of the point represents the meteor class.",
        "start": "02/15/2016",
        "updated": "03/02/2022",
        "source": "https://github.com/Lawlheart/FCC-Ziplines/tree/master/Zipline-20",
        "demo": "https://codepen.io/lawlheart/pen/VeRjXG"
      },
      {
        "name": "Exoplanet Heat Map",
        "skills": "HTML, CSS, JavaScript, AngularJS, d3.js",
        "description": "D3.js heat map comparing exoplanet radius, mass, and distance from Earth. Utilizes data from NASA Kepler Mission",
        "start": "02/04/2016",
        "updated": "02/09/2022",
        "source": "https://github.com/Lawlheart/FCC-Ziplines/tree/master/Zipline-18",
        "demo": "https://codepen.io/lawlheart/pen/wMEbBV"
      },
      {
        "name": "Conway's Game of Life",
        "skills": "HTML, CSS, JavaScript, React",
        "description": "Conway's game of life with grid size, generation speed, pause/play, and randomize functions. Built in React on CodePen.",
        "start": "01/31/2016",
        "updated": "01/30/2016",
        "source": "https://github.com/Lawlheart/FCC-Ziplines/tree/master/Zipline-14",
        "demo": "https://codepen.io/lawlheart/pen/oboRwo"
      },
      {
        "name": "Dungeon Game",
        "skills": "HTML, CSS, JavaScript, React",
        "description": "Roguelike dungeon game. Players can explore the dungeon, find gear, fight monsters, gain experience, and level up. Defeat the boss to win.",
        "start": "01/31/2016",
        "updated": "2/15/2024",
        "source": "https://github.com/Lawlheart/FCC-Ziplines/tree/master/Zipline-15",
        "demo": "https://codepen.io/lawlheart/pen/JGBazL"
      }
    ],
    experiences: [
      {
        "CODE": "ART",
        "client": "NH",
        "title": "Project C",
        "subtitle": "Health Program Analysis",
        "role": "Frontend",
        "duration": "2Y",
        "start": "02-14-2022",
        "end": "01-05-2024",
        "scope": "Development",
        "tools": "JavaScript, Angular, ngrx",
        "topics": "Agile, CI",
        "client_sector": "Health",
        "about": "Explored and implemented new frameworks, languages, and processes. Designed and implemented components in modern Angular using JavaScript, TypeScript, Sass, etc. Actively participated in a culture valuing thoughtful code reviews, testable code, and scalability. Brainstormed, designed, and implemented new products to impact the healthcare industry positively. Effectively participated in agile development processes, including backlog refinement, sprint planning, retrospectives, daily scrum, and CI/CD pipeline.",
        "private_notes": ""
      },
      {
        "CODE": "FIN",
        "client": "PHAS",
        "title": "Project F",
        "subtitle": "Digital Experience Transformation",
        "role": "Full Stack",
        "duration": "1Y1M",
        "start": "05-01-2019",
        "end": "06-01-2020",
        "scope": "Development, Consulting",
        "tools": "JavaScript, Angular, GraphQL",
        "topics": "Testing, Microapps, Agile, CI, DI, WC",
        "client_sector": "Financial",
        "about": "Worked with a large organization to combine and modernize their web services into micro-apps.  I built most of the front end of the support app myself, and in the process my techniques and best practices were often noticed and adopted by other teams and micro-apps. After that, I Improved the testing, validation, and stability of the support micro-app",
        "private_notes": ""
      },
      {
        "CODE": "NWH",
        "client": "PHAS",
        "title": "Project N",
        "subtitle": "Patient Experience App",
        "role": "App Dev",
        "duration": "4M",
        "start": "02-01-2019",
        "end": "06-01-2019",
        "scope": "Development",
        "tools": "JavaScript, React, React Native",
        "topics": "Agile, CI, DI",
        "client_sector": "Health",
        "about": "Worked on an agile team to build a react native app for a large healthcare provider",
        "private_notes": ""
      },
      {
        "CODE": "P2T",
        "client": "PHAS",
        "title": "Project P",
        "subtitle": "Agency Rebrand",
        "role": "Full Stack",
        "duration": "4M",
        "start": "06-01-2020",
        "end": "10-02-2020",
        "scope": "Development",
        "tools": "JavaScript, Lit",
        "topics": "Agile, CI, CMS, DI, WC",
        "client_sector": "Tech",
        "about": "Worked on an agile team to implement rebranding on the agency website. Was integral to ensuring that search and list pages worked properly at launch.",
        "private_notes": ""
      },
      {
        "CODE": "SSEP",
        "client": "SIMB",
        "title": "Project S",
        "subtitle": "Centralized Log Analysis",
        "role": "Full Stack",
        "duration": "1Y1M",
        "start": "06-01-2017",
        "end": "07-01-2018",
        "scope": "Development",
        "tools": "JavaScript, React, nodejs, SQL, rxjs, Docker",
        "topics": "Agile, CI, Testing, DI",
        "client_sector": "Financial",
        "about": "Worked on responsive enterprise web app in React that helps to deliver better and faster services by centralizing log analysis capabilities across all distributed components and systems",
        "private_notes": ""
      },
      {
        "CODE": "MLS",
        "client": "PHAS",
        "title": "Project M",
        "subtitle": "Medium-like Experience",
        "role": "Full Stack",
        "duration": "6M",
        "start": "07-01-2018",
        "end": "02-01-2019",
        "scope": "Development, Prototyping, Research",
        "tools": "JavaScript, React",
        "topics": "CMS, EX",
        "client_sector": "Entertainment",
        "about": "Prototyped a new editorial experience using headless drupal with a react frontend. Created a component system that dynamically loads react components using mobiledoc",
        "private_notes": ""
      },
      {
        "CODE": "JJ",
        "client": "PHAS",
        "title": "Project J",
        "subtitle": "Product Site System",
        "role": "Frontend",
        "duration": "6M",
        "start": "07-01-2018",
        "end": "02-01-2019",
        "scope": "Development, Prototyping, Research",
        "tools": "JavaScript, Vue, Gatsby, Nuxt, Slatejs",
        "topics": "EX",
        "client_sector": "Health",
        "about": "Researched and developed how to make thousands of similar, but different sites efficiently",
        "private_notes": ""
      },
      {
        "CODE": "DGA",
        "client": "SIMB",
        "title": "Project D",
        "subtitle": "Alumni Site",
        "role": "Full Stack",
        "duration": "1M",
        "start": "12-04-2017",
        "end": "01-15-2018",
        "scope": "Development",
        "tools": "JavaScript, Ruby, Rails",
        "topics": "CMS",
        "client_sector": "Education",
        "about": "Provide support and updates to existing rails alumni site",
        "private_notes": ""
      },
      {
        "CODE": "BCTS",
        "client": "SIMB",
        "title": "Project B",
        "subtitle": "Candidate Management System",
        "role": "Full Stack",
        "duration": "1Y",
        "start": "05-31-2017",
        "end": "05-07-2018",
        "scope": "Development",
        "tools": "JavaScript, Ruby, Rails",
        "topics": "CMS",
        "client_sector": "Tech",
        "about": "Extended and maintained existing candidate management system in rails",
        "private_notes": ""
      },
      {
        "CODE": "MCE",
        "client": "SIMB",
        "title": "Project A",
        "subtitle": "Dashboard and Panels",
        "role": "Frontend",
        "duration": "3M",
        "start": "12-04-2017",
        "end": "03-26-2018",
        "scope": "Development",
        "tools": "JavaScript, Angular",
        "topics": "DI",
        "client_sector": "Tech",
        "about": "Implemented dashboard and panel implementation based on design wireframes",
        "private_notes": ""
      },
      {
        "CODE": "Radar",
        "client": "PHAS",
        "title": "Project R",
        "subtitle": "Tech Forecast Tool",
        "role": "Full Stack",
        "duration": "4M",
        "start": "06-01-2020",
        "end": "10-02-2020",
        "scope": "Development",
        "tools": "JavaScript, React, GraphQL",
        "topics": "CMS, DI",
        "client_sector": "Tech",
        "about": "Developed an internal tool that is to be used for hiring and management decisions for a medium sized software agency",
        "private_notes": ""
      },
    ]
  }, {
    name: 'RJ',
    title: 'Digital Accessibility Analyst',
    nav: 'RJ',
    link: 'rj',
    codepen: 'raehart',
    image: 'https://avatars.githubusercontent.com/u/13499408',
    about: [
      'UX Web Designer and community-focused leader with a passion for creating accessible and inclusive digital experiences.',
      'Strong background in front-end development and a deep commitment to diversity and inclusion, I work to bridge the gap between design and usability.',
      'Goal is to make the digital world more welcoming and usable for everyone, regardless of their abilities.'
    ],
    theme: {
      color: '#b073d9',
      backgroundColor: 'rgba(42, 16, 60, 0.90)',
      titleBackgroundColor: 'rgba(42, 16, 60, 0.90)',
      glow: true
    },
    links: [
      {
        name: 'raehart',
        site: 'Codepen',
        url: 'https://codepen.io/raehart',
      },
      {
        name: 'raehart',
        site: 'GitHub',
        url: 'https://github.com/raehart',
      },
      {
        name: 'rj-urquhart',
        site: 'LinkedIn',
        url: 'https://linkedin.com/in/rj-urquhart/',
      }
    ],
    certifications: [
      {
        name: "Responsive Web Design",
        prefix: "RD",
        issuer: "FreeCodeCamp",
        cred: "https://www.freecodecamp.org/certification/raehart/responsive-web-design",
        projects: [
          {
            "certCode": "RD-01",
            "name": "Tribute Page - Gorrilaz",
            "skills": "Responsive Design, JavaScript, HTML, CSS",
            "description": "Tribute page for Gorrilaz depicting band members",
            "start": "09/02/2021",
            "updated": "09/24/2021",
            "source": "https://codepen.io/raehart/full/BaZzRXz",
            "demo": "https://codepen.io/raehart/pen/BaZzRXz"
          },
          {
            "certCode": "RD-02",
            "name": "Survey Form - Adventurers Wanted",
            "skills": "Responsive Design, JavaScript, HTML, CSS",
            "description": "Virtual Role Playing Game Sign-Up Sheet",
            "start": "09/04/2021",
            "updated": "09/24/2021",
            "source": "https://codepen.io/raehart/pen/mdwrpWw",
            "demo": "https://codepen.io/raehart/pen/mdwrpWw"
          },
          {
            "certCode": "RD-03",
            "name": "Product Landing Page - Mystery Shack Internet Thingy",
            "skills": "Responsive Design, JavaScript, HTML, CSS",
            "description": "Shop page for the Mystery Shack",
            "start": "09/08/2021",
            "updated": "11/03/2021",
            "source": "https://codepen.io/raehart/full/RwgKLoL",
            "demo": "https://codepen.io/raehart/pen/RwgKLoL"
          },
          {
            "certCode": "RD-04",
            "name": "Technical Documentation Page - RJ Investigates",
            "skills": "Responsive Design, JavaScript, HTML, CSS",
            "description": "Documentation page exploring popular programming languages",
            "start": "09/14/2021",
            "updated": "11/12/2021",
            "source": "https://codepen.io/raehart/full/GREMYqb",
            "demo": "https://codepen.io/raehart/pen/GREMYqb"
          },
          {
            "certCode": "RD-05",
            "name": "Portfolio - RJ",
            "skills": "Responsive Design, JavaScript, HTML, CSS",
            "description": "Portfolio for RJ with all 4 other responsive design projects",
            "start": "09/24/2021",
            "updated": "8/6/2024",
            "source": "https://codepen.io/raehart/full/qBjJPWK",
            "demo": "https://codepen.io/raehart/pen/qBjJPWK"
          }
        ],
      }
    ],
    projects: [
      {
        "name": "PokeLab",
        "skills": "TypeScript, Vue, Pinia, HTML, CSS, JavaScript",
        "description": "Pokemon Multitool site in development",
        "start": "08/4/2024",
        "updated": "8/20/2024",
        "source": "https://github.com/loreheart/poke-lab",
        "demo": "https://pokelab.loreheart.com"
      },
      {
        "name": "Pokedex",
        "skills": "HTML, CSS, JavaScript, Angular, Vue",
        "description": "Pokedex site orignally built using AngularJS, Sass, and PokeApi. CodePen version is in Vue.",
        "start": "07/23/2015",
        "updated": "2/9/2024",
        "source": "https://github.com/raehart/pokedex",
        "demo": "https://codepen.io/raehart/pen/zYdGKvX"
      },
      {
        "name": "RJ Investigates",
        "skills": "Responsive Design, JavaScript, HTML, CSS",
        "description": "Documentation page exploring popular programming languages",
        "start": "09/14/2021",
        "updated": "11/12/2021",
        "source": "https://codepen.io/raehart/pen/GREMYqb",
        "demo": "https://codepen.io/raehart/full/GREMYqb"
      },
      {
        "name": "Portfolio - RJ",
        "skills": "Responsive Design, JavaScript, HTML, CSS",
        "description": "Portfolio made as part of the Responsive Web Design FreeCodeCamp certificate with all 4 other responsive design projects",
        "start": "09/24/2021",
        "updated": "8/6/2024",
        "source": "https://codepen.io/raehart/pen/qBjJPWK",
        "demo": "https://codepen.io/raehart/full/qBjJPWK"
      }
    ],
    experiences: [
      {
        "CODE": "SSEP",
        "client": "SIMB",
        "title": "Project S",
        "subtitle": "Centralized Log Analysis",
        "role": "Developer",
        "start": "08-01-2017",
        "end": "06-01-2018",
        "scope": "Development",
        "tools": "JavaScript, Node.js",
        "topics": "Testing",
        "client_sector": "Tech, Data",
        "about": "Developed testing for an app that helps organizations analyze their log files.",
      },
      {
        "CODE": "SSEP",
        "client": "SIMB",
        "title": "Project M",
        "subtitle": "Dashboard and Panels",
        "role": "Developer",
        "start": "08-01-2017",
        "end": "06-01-2018",
        "scope": "Development",
        "tools": "JavaScript, Angular",
        "topics": "User Experience, User Interface",
        "client_sector": "Tech, Security",
        "about": "Implemented dashboard and panel implementation based on design wireframes.",
      },
      {
        "CODE": "PDS",
        "client": "PHAS",
        "title": "Project P",
        "subtitle": "Particle Design System",
        "role": "Developer",
        "start": "06-01-2018",
        "end": "11-01-2019",
        "scope": "Development",
        "tools": "JavaScript, HTML5, CSS3, twig, docker",
        "topics": "Acessbility, User Experience, User Interface, Design, Documentation",
        "client_sector": "Tech, Design",
        "about": "Helped develop, maintain and provide documentation for Particle Design System",
      },
      {
        "CODE": "UNI",
        "client": "UNI",
        "title": "Project U",
        "subtitle": "Mobile Site Builder",
        "role": "Developer",
        "start": "04-01-2022",
        "end": "06-30-2022",
        "scope": "Development",
        "tools": "JavaScript, HTML5, CSS3",
        "topics": "Accessbility, User Experience, User Interface",
        "client_sector": "Tech, Design",
        "about": "Helped develop a site building system.",
      },
    ]
  }
]
