<script setup lang="ts">

</script>

<template>
  <div id="footer" class="navbar text-lh-lore flex py-2 mt-4 bg-lh-banner text-lg justify-center">
    <a class="text-lh-green-light px-2" target="_blank" href="https://gala.loreheart.com">&#169; 2024</a>
    <a class="text-lh-lore" target="_blank" href="https://gala.loreheart.com/loriet">Lore</a>
    <a class="text-lh-heart" target="_blank" href="https://gala.loreheart.com/rj">Heart</a>
  </div>
</template>

<style scoped lang="scss">
  #footer {
    /* background-color: #880000;
    color: #ffffff; */
  }

</style>
