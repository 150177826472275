import { createApp } from 'vue'
import { createPinia } from 'pinia'

import AppBody from './components/AppBody.vue'
import router from './router.ts'
import { vuetify } from './middleware/index.ts'
import './styles/main.scss'

const pinia = createPinia()

createApp(AppBody)
  .use(router)
  .use(pinia)
  .use(vuetify)
  .mount('#app')
