import { defineStore } from 'pinia'

import { LOREHEART_PROFILES, LOREHEART_THEMES } from '../config'
import type { Profile } from '../types'

export const useProfileStore = defineStore('profiles', () => {
  const getAllProfiles = () => {
    return LOREHEART_PROFILES
  }

  const getProfile = (link: string): Profile | null => {
    return LOREHEART_PROFILES.find(
      (profile: Profile) => link === profile.link
    ) as Profile || null
  }
  
  const getProfileTheme = (link: string) => {
    const profile = getProfile(link)
    return profile ? profile.theme : LOREHEART_THEMES.default
  }

  return {
    getAllProfiles,
    getProfile,
    getProfileTheme,
  }
})
